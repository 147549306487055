<template>
    <v-dialog v-model="notificationDialog" persistent max-width="600">
        <v-card>
            <v-card-title>Enable Notifications</v-card-title>
            <v-card-text>
                <Vue3Lottie :animationData="Bell" :height="200" :width="300" :speed="1" />
                It looks like you've disabled notifications. Would you like to enable them to stay updated?
            </v-card-text>
            <v-card-actions>
                <v-checkbox v-model="dontShowAgain" label="Don't show again"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="requestNotificationPermission">Enable</v-btn>
                <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Bell from "@/assets/animjson/bell.json";
import { getToken, onMessage } from "firebase/messaging";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { messaging, firestore } from "@/firebase"; // Assuming these are set up

export default {
    name: "AllowNotification",
    data() {
        return {
            notificationDialog: false,
            dontShowAgain: false,
            Bell,
        };
    },
    methods: {
        checkNotificationPermission() {
            const permission = Notification.permission;
            const dontShowAgainState = localStorage.getItem("dontShowAgain") === "true";

            if (permission != "granted" && !dontShowAgainState) {
                this.notificationDialog = true;
            }

            console.log("Notification permission " + permission);
        },
        requestNotificationPermission() {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    localStorage.setItem("dontShowAgain", "true");
                    this.retrieveFCMToken();
                }
                console.log("Notification permission " + permission);
                this.notificationDialog = false;
                if (this.dontShowAgain) {
                    localStorage.setItem("dontShowAgain", "true");
                }
            });
        },
        retrieveFCMToken() {
            const permission = Notification.permission;
            let vapidKeyString = null;
            if (process.env.NODE_ENV === "development" || ["beta.workflow.printcarepremedia.com", "ppm-workflow-dev.web.app", "ppm-workflow-dev.firebaseapp.com"].includes(window.location.hostname)) {
                vapidKeyString = 'BIaNsf3koT5uOtTTlbDmKZ1vp3FyepSIalniXCr5ufJE8fR77CmnZYv5KMDEpOgXfmgKAmoP7mDolExwaJpwD5U';
            } else if (process.env.NODE_ENV == "production") {
                vapidKeyString = 'BLRvshdbZJcwoxxt8iD4w0dchayn3JJAsHyK9llssSP6MeHupwhQ4jdIkeEzHMAkS4KG770jL2iqrMr3Y_nJGgE';
            }

            console.log("Notification permission " + permission);

            if (permission == "granted") {
                getToken(messaging, { vapidKey: vapidKeyString })
                    .then(async (currentToken) => {
                        console.log("Current Token " + currentToken);
                        if (currentToken) {
                            const userRef = doc(firestore, "users", localStorage.uid);
                            const userDoc = await getDoc(userRef);

                            let tokens = [];
                            if (userDoc.exists()) {
                                tokens = userDoc.data().token || [];
                                tokens = [...new Set(tokens)];
                                if (tokens.length >= 5) tokens.shift();
                                tokens.push(currentToken);
                                await updateDoc(userRef, { token: tokens });
                            } else {
                                await setDoc(userRef, { token: [currentToken] });
                            }
                        } else {
                            console.log("No registration token available.");
                        }
                    })
                    .catch((err) => {
                        console.log("Error retrieving token.", err);
                    });
            }
        },
        closeDialog() {
            this.notificationDialog = false;
            if (this.dontShowAgain) {
                localStorage.setItem("dontShowAgain", "true");
            }
        },
    },
    mounted() {
        this.checkNotificationPermission();
    },
};
</script>